import { AddCircle } from '@mui/icons-material';
import {
  Box,
  Card,
  CardMedia,
  Stack,
  Typography,
  alpha,
  useTheme
} from '@mui/material';
import { appGasService } from 'config/services/appgas';
import { formatPrice } from 'helpers/formatPrice';
import { useEffect, useState } from 'react';
import { Product } from 'types';
import { IProductsState } from 'views/Resellers/components/ChoiceResellers';

interface AdditionalOffersProps {
  chosenProducts: IProductsState[];
  setChosenProducts: (input: IProductsState[]) => void;
  zone_id: string;
}

export function AdditionalOffers(props: AdditionalOffersProps) {
  const theme = useTheme();
  const { setChosenProducts, chosenProducts, zone_id } = props;
  const { palette } = useTheme();
  const [additionalOffers, setAdditionalOffers] = useState<Product[]>([]);

  async function getAdditionalOffersProducts() {
    const payload = {
      selected_products: chosenProducts.map((product) => product.id),
      zone_id: zone_id
    };

    try {
      const { data } = await appGasService.post(
        `/v2/integrations/products/additional_offers`,
        payload
      );
      setAdditionalOffers(data);
    } catch (error) {
      console.log('additional offers error: ', error);
    }
  }

  function handleProductChoice(product: IProductsState) {
    const chosenProductsUpdated = chosenProducts.length
      ? chosenProducts.map((p) => {
          if (p.id === product.id) {
            return { ...p, quantity: ++p.quantity };
          }

          return p;
        })
      : [];

    const productAlreadyChosen =
      chosenProducts.length && chosenProducts.find((p) => p.id === product.id);

    if (!productAlreadyChosen)
      chosenProductsUpdated.push({ ...product, quantity: 1 });

    setChosenProducts(chosenProductsUpdated);
  }

  useEffect(() => {
    chosenProducts && getAdditionalOffersProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenProducts]);

  function ProductItem({ product }: { product: Product }) {
    return (
      <Stack
        direction={'column'}
        gap={'8px'}
        width={'80px'}
        sx={{ cursor: 'pointer' }}
        onClick={() => handleProductChoice(product)}
      >
        <Box
          width={'80px'}
          height={'80px'}
          borderRadius={'8px'}
          border={`solid 1px ${alpha('#000', 0.1)}`}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          position={'relative'}
        >
          <CardMedia
            component="img"
            image={product.picture}
            alt="Live from space album cover"
            sx={{ height: '60px', width: '60px', objectFit: 'contain' }}
          />
          <AddCircle
            sx={{
              color: theme.palette.green.main,
              position: 'absolute',
              right: '5px',
              bottom: '5px',
              fontSize: '22px'
            }}
          />
        </Box>
        <Stack direction={'column'} gap={'4px'}>
          <Stack direction={'row'}>
            <Stack
              alignItems={'center'}
              justifyContent={'center'}
              bgcolor={product.brand_color}
              px={'5px'}
              py={'3px'}
              borderRadius={'3px'}
            >
              <Typography
                fontSize={'8px'}
                fontWeight={'700'}
                lineHeight={'9px'}
                color={'#fff'}
              >
                {product.brand_name}
              </Typography>
            </Stack>
          </Stack>
          <Typography fontWeight={'600'} fontSize={'12px'} lineHeight={'15px'}>
            {product.desc}
          </Typography>
          <Typography
            fontWeight={'500'}
            fontSize={'12px'}
            lineHeight={'15px'}
            sx={{ color: palette.grey[700] }}
          >
            {formatPrice(product.price)}
          </Typography>
        </Stack>
      </Stack>
    );
  }

  if (additionalOffers.length <= 0) {
    return null;
  }

  return (
    <Stack direction={'column'} gap={'8px'}>
      <Typography fontSize={'14px'} fontWeight={'600'}>
        Compre também:
      </Typography>
      <Card
        variant="custom"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          px: '15px',
          py: '15px'
        }}
      >
        <Box width={'100%'}>
          <Stack direction={'column'} gap={'8px'}>
            <Stack
              direction={'row'}
              gap={'12px'}
              sx={{ overflowX: 'auto', overflowY: 'hidden' }}
              pb={'10px'}
            >
              {additionalOffers.map((product, index) => (
                <ProductItem product={product} key={index} />
              ))}
            </Stack>
          </Stack>
        </Box>
      </Card>
    </Stack>
  );
}
