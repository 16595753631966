import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Container } from '../../components/Container';
import { readStorage, useLocalStorage } from 'hooks/useLocalStorage';
import { Reseller } from '../Resellers/components/ChoiceResellers';
import { appGasService } from 'config/services/appgas';
import { Stepper } from '../../components/Stepper';

import { AddressInputData } from 'views/SaveAddress/types';
import { PaymentMethods } from './components/PaymentMethods';

import {
  Box,
  Card,
  Typography,
  useTheme,
  useMediaQuery,
  CardContent,
  Divider,
  Stack,
  Skeleton
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import SummaryContent from './components/SummaryContent';
import { CheckoutCardContent } from './components/CheckoutCardContent';
import PreviousAndNextButtons from 'components/PreviousAndNextButtons';
import Coupon from './components/Coupon';

import { useCheckout } from './hooks/useCheckoutContext';
import { trackEvent } from 'config/services/adjustTracking';
import { useHeader } from 'hooks/useHeaderContext';
import { gasToast } from 'helpers/toasts';
import { differenceInHours, parse } from 'date-fns';
import { grey } from '@mui/material/colors';
import {
  eventErrorOrder,
  eventInProgressOrder,
  eventPurchase
} from 'config/services/gtmEvents';
import { SourceIntegrationContext } from 'providers/source-integration-context/source-integration-context';
import { ChosenProductsContext } from 'providers/chosen-products-context/chosen-products-contex';
import { LeadDataContext } from 'providers/lead-data-context/lead-data.context';
import { ResellerContext } from 'providers/reseller-context/reseller-context';
import { useAddresses } from 'hooks/useAddressesContext';
import { SummaryContext } from 'providers/summary-context/summary.context';
import { OrderContext } from 'providers/order-context/order.context';
import { CreateOrderUseCase } from 'use-cases/orders/create-order.use-case';
import SmashGiftCards from './components/SmashGiftCards/SmashGiftCards';
import { toPattern } from 'vanilla-masker';
import { TotalPrice } from 'views/Cart/components/total-price/total-price';
import { formatPrice } from 'helpers/formatPrice';

interface IProductsState {
  id: string;
  name: string;
  desc: string;
  picture: string;
  price: number;
  quantity: number;
}

export default function Review() {
  const customerToken = readStorage('customer_token');

  const { integration } = useContext(SourceIntegrationContext);
  const sourceIntegration = integration.source;

  const { setOrder } = useContext(OrderContext);

  const navigate = useNavigate();

  const { palette, breakpoints } = useTheme();
  const {
    change,
    paymentId,
    formIsValid,
    validate,
    cardNumber,
    securityCode,
    cardholderName,
    cpf,
    firstName,
    lastName,
    email
  } = useCheckout();

  const [loading, setLoading] = useState(false);

  const isSmallScreen = useMediaQuery(breakpoints.down('md'));

  const { summaryData, setSummaryData } = useContext(SummaryContext);

  const { addressDataInput, createAddress } = useAddresses();
  const address = addressDataInput || ({} as AddressInputData);

  const [checkoutLastTime] = useLocalStorage('checkout_last_time', '');

  const clear_sale_session_id = readStorage('clearSaleSid');

  const { reseller, setReseller } = useContext(ResellerContext);

  const { chosenProducts, setChosenProducts } = useContext(
    ChosenProductsContext
  );

  const { leadData } = useContext(LeadDataContext);
  const user = leadData;

  const { setSubtitle, setTitle, setEditAddress } = useHeader();

  const [isSummaryLoading, setIsSummayLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!customerToken) return navigate('/create-lead');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerToken]);

  useEffect(() => {
    if (!reseller?.id && !chosenProducts.length) {
      navigate('/product-choice');
    }

    if (!reseller?.id && chosenProducts.length) {
      navigate('/resellers');
    }
  }, [reseller, navigate, chosenProducts.length]);

  useEffect(() => {
    const lastCheckout = parse(
      checkoutLastTime,
      'dd/MM/yyyy, HH:mm:ss',
      new Date()
    );

    if (differenceInHours(lastCheckout, new Date()) >= 4) {
      setReseller({} as Reseller);
      setChosenProducts([] as IProductsState[]);

      navigate('/product-choice');
    }
  }, [checkoutLastTime, navigate, setChosenProducts, setReseller]);

  useEffect(() => {
    setTitle('Detalhes do pedido');
    setEditAddress(false);
  }, [setSubtitle, setTitle, setEditAddress, user]);

  const changeMoney = () => {
    const cleanChange = change.match(/\d+,\d+/g) || '0,00';
    return `${cleanChange[0].replace(',', '.')}`;
  };

  async function handleCreateOrder() {
    if (!reseller || !reseller.id) {
      return;
    }

    setLoading(true);

    const payload = {
      paymentId,
      sourceIntegration,
      change: changeMoney(),
      addressId: address.id,
      companyId: reseller.id,
      salesChannel: 'compraweb',
      productIds: chosenProducts.map((product) => {
        return { ...product, brand_id: product.brand_id || reseller.brand_id };
      }),
      coupon: summaryData?.coupon || '',
      brand_id: reseller.brand_id,
      giftcard: summaryData?.giftcard || '',
      card: {
        card_number: toPattern(cardNumber, '9999999999999999'),
        holder_name: cardholderName,
        expiration_month: validate?.split('/')[0] || '',
        expiration_year: validate?.split('/')[1] || '',
        security_code: securityCode
      },
      cpf,
      firstName,
      lastName,
      name: leadData.name,
      clear_sale_session_id,
      email
    };

    await new CreateOrderUseCase()
      .handle(payload)
      .then((data) => {
        setOrder(data);
        gasToast({ type: 'success', message: 'Pedido criado com sucesso.' });

        eventPurchase(
          summaryData?.coupon || '',
          data?.payment_method?.name || '',
          data?.total || 0,
          data?.order_number || 0,
          data?.products
        );

        trackEvent({
          sourceIntegration,
          eventToken: 'Comprou'
        });
        eventInProgressOrder();

        navigate('/order-tracking');
      })
      .catch((e) => {
        let message = 'Ocorreu um erro ao tentar criar o pedido.';

        if (axios.isAxiosError(e)) {
          message = e?.response?.data?.message;
        }

        eventErrorOrder(message);

        toast.error(`${message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const fetchSummary = async () => {
    if (!reseller || !reseller.id) {
      return;
    }

    let addressId = null;

    try {
      if (!addressDataInput?.id) {
        await createAddress().then((data) => {
          addressId = data.id;
        });
      }
      setIsSummayLoading(true);

      const { data } = await appGasService.post(
        `/v3/integrations/orders/summary`,
        {
          company_id: reseller.id,
          address_id: addressDataInput?.id || addressId,
          coupon: summaryData?.coupon || '',
          product_ids: chosenProducts.map((product) => {
            return {
              ...product,
              brand_id: product.brand_id || reseller.brand_id
            };
          }),
          sales_channel: 'compraweb',
          brand_id: reseller.brand_id,
          giftcard: summaryData?.giftcard || ''
        }
      );

      const summaryTime = new Date();
      setSummaryData({ ...summaryData, ...data, summary_time: summaryTime });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error?.response?.data?.message);
      }
    } finally {
      setIsSummayLoading(false);
    }
  };

  useEffect(() => {
    fetchSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenProducts, summaryData?.coupon, summaryData?.giftcard]);

  useEffect(() => {
    (async () => {
      if (!customerToken || !reseller || !reseller.id || !addressDataInput?.id)
        return;

      setIsSummayLoading(true);

      setIsSummayLoading(true);

      setIsSummayLoading(true);

      try {
        const { data } = await appGasService.post(
          `/v3/integrations/orders/summary`,
          {
            coupon: summaryData?.coupon,
            sales_channel: 'compraweb',
            company_id: reseller.id,
            product_ids: chosenProducts.map((product) => {
              return {
                ...product,
                brand_id: product.brand_id || reseller.brand_id
              };
            }),
            address_id: address.id,
            brand_id: reseller.brand_id,
            giftcard: summaryData?.giftcard || ''
          }
        );

        setSummaryData({ ...data });
      } catch (error) {
        let message;

        if (axios.isAxiosError(error)) {
          message = error?.response?.data?.message;
        }

        toast.error(
          message || 'Ocorreu um erro ao tentar gerar o resumo do pedido',
          {
            position: 'bottom-center',
            autoClose: 5000,
            draggable: true,
            closeOnClick: true,
            pauseOnHover: true,
            hideProgressBar: false
          }
        );
      } finally {
        setIsSummayLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items_string = chosenProducts
    .map(
      (p) => 'product: ' + p.name + ' x' + p.quantity + ' - price: ' + p.price
    )
    .join(', ');

  return (
    <Container>
      <Box
        sx={{
          mt: '2rem',
          backgroundColor: 'white',
          padding: '15px',
          borderRadius: '8px',
          border: '1px solid #e0e0e0'
        }}
      >
        <Stepper
          totalSteps={sourceIntegration !== 'ifood' ? 3 : 3}
          currentStep={sourceIntegration !== 'ifood' ? 3 : 3}
          title={'Confirmação do pedido'}
          showStepInfo={true}
        />
      </Box>

      <Box
        sx={{
          mt: '16px',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px'
        }}
      >
        <Typography
          fontSize={'14px'}
          fontWeight={'600'}
          sx={{ color: palette.grey[900] }}
        >
          Resumo do pedido:
        </Typography>
        <Card
          variant="custom"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <CardContent sx={{ p: '12px' }}>
            <Coupon
              setSummary={setSummaryData}
              couponApplied={{
                label: summaryData?.coupon || '',
                discountText: summaryData?.coupon_discount_text || ''
              }}
            />
          </CardContent>

          <CardContent
            sx={{
              p: '12px',
              borderTop: `1px solid ${palette.grey[300]}`
            }}
          >
            <SmashGiftCards />
          </CardContent>
          <CheckoutCardContent>
            {summaryData && (
              <SummaryContent
                summary={summaryData}
                isSummaryLoading={isSummaryLoading}
              />
            )}
          </CheckoutCardContent>
        </Card>
      </Box>

      {!!reseller?.payment_methods?.length && summaryData?.total && (
        <PaymentMethods
          purchaseTotal={summaryData.total}
          paymentMethods={reseller.payment_methods}
          couponCode={summaryData?.coupon || ''}
          products={chosenProducts}
          items={items_string}
        />
      )}

      {!isSmallScreen && (
        <>
          <Stack py="20px">
            <Divider
              sx={{ mt: '8px', mb: '8px', borderColor: palette.grey[300] }}
            />
          </Stack>
          <TotalPrice
            totalPrice={summaryData?.total}
            isLoading={isSummaryLoading}
          />
        </>
      )}

      {isSmallScreen && <Box sx={{ mt: '110px' }}></Box>}
      <Box
        sx={{
          position: 'sticky',
          paddingY: '20px',
          bottom: 0,
          backgroundColor: grey[100]
        }}
      >
        <PreviousAndNextButtons
          disableNextButton={!formIsValid || loading}
          previousActionText={`${
            loading ? 'Confirmando pedido...' : 'Confirmar pedido'
          }`}
          nextAction={async () => {
            await handleCreateOrder();
          }}
          previousActionButtonColor={palette.green.main}
          previousAction={() => navigate('/cart')}
        >
          {isSmallScreen && (
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              pb={'20px'}
              alignItems={'center'}
            >
              <Typography
                sx={{ fontSize: '12px' }}
                variant="caption"
                lineHeight={'11px'}
                color={palette.grey[600]}
                fontWeight={'600'}
              >
                Total do pedido:
              </Typography>
              {isSummaryLoading ? (
                <Skeleton variant="text" width={'40px'} sx={{ p: '0' }} />
              ) : (
                <Typography
                  sx={{ fontSize: '12px' }}
                  variant="caption"
                  lineHeight={'11px'}
                  color={palette.grey[800]}
                  fontWeight={'600'}
                >
                  {summaryData?.total && formatPrice(summaryData?.total)}
                </Typography>
              )}
            </Stack>
          )}
        </PreviousAndNextButtons>
      </Box>
    </Container>
  );
}
