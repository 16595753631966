/* eslint-disable react-hooks/exhaustive-deps */
import {
  AccessTime,
  AlarmOn,
  AppSettingsAlt,
  CheckCircleOutline,
  ContentCopy,
  Pix,
  Send
} from '@mui/icons-material';
import { IconButton, ModalTitle } from './styles';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
  useTheme,
  IconButton as MuiIconButton,
  TextField,
  Button
} from '@mui/material';
import { toast } from 'react-toastify';
import { LinearProgressBar } from 'components/Stepper/style';
import {
  addMinutes,
  differenceInMilliseconds,
  differenceInMinutes,
  differenceInSeconds,
  minutesToSeconds
} from 'date-fns';
import { useEffect, useState } from 'react';

type ModalProps = {
  open: boolean;
  pixCode: string;
  onClose: () => void;
  createdAt: string;
  price: string;
};

export default function PixInAppModal(props: ModalProps) {
  const { onClose, open, pixCode, createdAt, price } = props;
  const theme = useTheme();
  const orderCreatedAt = createdAtToDate();
  const [today, setToday] = useState<Date>(new Date());
  const difference = differenceInMinutes(today, orderCreatedAt);
  const timeLimit = 10;
  const progress = (difference / timeLimit) * 100;
  const progressInSeconds =
    (differenceInSeconds(today, orderCreatedAt) / minutesToSeconds(timeLimit)) *
    100;
  const isCompleted = progress >= 100;
  const secondsLeft = minutesToSeconds(10 - difference);
  const endDate = addMinutes(orderCreatedAt, 10);
  const timeDifferenceInMilliseconds = differenceInMilliseconds(endDate, today);
  const minutes = Math.floor(timeDifferenceInMilliseconds / 60000);
  const seconds = Math.floor((timeDifferenceInMilliseconds % 60000) / 1000);
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');
  const formattedTimeLeft = `${formattedMinutes}:${formattedSeconds}`;

  useEffect(() => {
    setInterval(() => setToday(new Date()), 1000);
  }, []);

  function createdAtToDate() {
    const splitedDateAndHour = createdAt.split(' ');
    const splitedDate = splitedDateAndHour[0].split('/');
    const splitedHour = splitedDateAndHour[1].split(':');

    return new Date(
      +splitedDate[2],
      +splitedDate[1] - 1,
      +splitedDate[0],
      +splitedHour[0],
      +splitedHour[1],
      0
    );
  }

  if (secondsLeft <= 0) {
    return (
      <>
        <Dialog
          open={open}
          maxWidth="sm"
          fullWidth={true}
          onClose={onClose}
          sx={{ padding: '24px' }}
        >
          <DialogTitle>
            <ModalTitle>O tempo do pagamento pix encerrou</ModalTitle>
            <IconButton aria-label="close-modal" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Stack direction={'column'} width={'100%'} gap={'16px'}>
              <Typography
                fontWeight={'400'}
                fontSize={'14px'}
                color={theme.palette.grey[600]}
              >
                {`Caso já tenha efetuado o pagamento, selecione "Já efetuei o
                pagamento" e aguardar a aprovação (a aprovação pode levar até 5
                minutos).`}
              </Typography>
              <Stack direction={'row'} gap={'16px'} justifyContent={'flex-end'}>
                <Button variant="contained" onClick={onClose}>
                  Já efetuei o pagamento
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      </>
    );
  }

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={onClose}
        sx={{ padding: '24px' }}
      >
        <DialogTitle>
          <ModalTitle>Pagamento com pix</ModalTitle>
          <IconButton aria-label="close-modal" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack direction={'column'} width={'100%'} gap={'24px'}>
            <Stack
              direction={'column'}
              width={'100%'}
              border={`solid 1px ${theme.palette.grey[300]}`}
              borderRadius={'8px'}
            >
              <Stack
                direction={'row'}
                p={'12px 16px'}
                justifyContent={'space-between'}
                bgcolor={theme.palette.grey[50]}
                borderBottom={`solid 1px ${theme.palette.grey[300]}`}
              >
                <Typography variant="body1" fontWeight={'700'}>
                  Total a pagar
                </Typography>
                <Typography variant="body1" fontWeight={'700'}>
                  {price}
                </Typography>
              </Stack>
              <Stack direction={'column'} p={'12px 16px'} gap={'8px'}>
                <Typography
                  variant="body2"
                  fontWeight={'400'}
                  color={theme.palette.grey[600]}
                >
                  Tempo restante para efetuar pagamento
                </Typography>
                <Stack direction={'row'} gap={'4px'} alignItems={'center'}>
                  <AccessTime
                    sx={{
                      color: theme.palette.primary.xxdark,
                      fontSize: '20px'
                    }}
                  />
                  <Typography
                    variant="h6"
                    fontWeight={'600'}
                    color={theme.palette.primary.xxdark}
                  >
                    {formattedTimeLeft}
                  </Typography>
                </Stack>
                <LinearProgressBar
                  variant="determinate"
                  value={isCompleted ? 100 : progressInSeconds}
                  sx={{
                    backgroundColor: theme.palette.grey[200],
                    height: '8px'
                  }}
                />
                <Typography
                  variant="caption"
                  fontWeight={'400'}
                  fontSize={'12px'}
                  lineHeight={'15px'}
                  color={theme.palette.grey[600]}
                >
                  Efetue o pagamento em até 10 minutos, após esse tempo o código
                  abaixo será anulado.
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={'column'} gap={'16px'}>
              <Typography fontSize={'16px'} fontWeight={'600'}>
                Copie este código para pagar
              </Typography>
              <Stack direction={'column'} gap={'12px'}>
                <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
                  <ContentCopy sx={{ fontSize: '16px' }} color="primary" />
                  <Typography
                    fontWeight={'400'}
                    fontSize={'14px'}
                    lineHeight={'16px'}
                  >
                    Copie o código de pagamento abaixo
                  </Typography>
                </Stack>
                <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
                  <AppSettingsAlt sx={{ fontSize: '16px' }} color="primary" />
                  <Typography
                    fontWeight={'400'}
                    fontSize={'14px'}
                    lineHeight={'16px'}
                  >
                    Abra o aplicativo do seu banco
                  </Typography>
                </Stack>
                <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
                  <Pix sx={{ fontSize: '16px' }} color="primary" />
                  <Typography
                    fontWeight={'400'}
                    fontSize={'14px'}
                    lineHeight={'16px'}
                  >
                    Escolha a opção PIX
                  </Typography>
                </Stack>
                <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
                  <Send sx={{ fontSize: '16px' }} color="primary" />
                  <Typography
                    fontWeight={'400'}
                    fontSize={'14px'}
                    lineHeight={'16px'}
                  >
                    Use o {` "pix copie e cola" `}
                  </Typography>
                </Stack>
                <Stack direction={'row'} alignItems={'center'} gap={'8px'}>
                  <AlarmOn sx={{ fontSize: '16px' }} color="primary" />
                  <Typography
                    fontWeight={'400'}
                    fontSize={'14px'}
                    lineHeight={'16px'}
                  >
                    Aprovação do pix em até 5 minutos
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                direction={'row'}
                width={'100%'}
                bgcolor={theme.palette.grey[50]}
                height={'56px'}
                border={`solid 1px ${theme.palette.grey[300]}`}
                borderRadius={'8px'}
                justifyContent={'space-between'}
                gap={'10px'}
              >
                <TextField
                  value={pixCode}
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      color: theme.palette.grey[700],
                      fontWeight: '400',
                      '& fieldset': {
                        border: 'none'
                      },
                      '&:hover fieldset': {
                        border: 'none'
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none'
                      }
                    }
                  }}
                  InputProps={{
                    readOnly: true
                  }}
                />
                <MuiIconButton
                  sx={{ width: '50px' }}
                  onClick={() => {
                    navigator.clipboard.writeText(pixCode);
                    toast.success(`Código pix copiado`, {
                      autoClose: 5000,
                      draggable: true,
                      theme: 'colored',
                      closeOnClick: true,
                      pauseOnHover: true,
                      closeButton: false,
                      hideProgressBar: false,
                      position: 'bottom-center',
                      icon: () => <CheckCircleOutline />
                    });
                  }}
                >
                  <ContentCopy color="primary" />
                </MuiIconButton>
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
